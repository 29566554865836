<template>
    <div>
        <ContextTitle
            :passedActions="titleBarActions" 
            title="Create Partner"
        />
        <span class="required-bar"></span>
        <span
            v-if="error"
            class="partner-form-error-bar"
        >
        {{ error }}
        </span>
        <PartnerForm
            :partner="partner"
        />

    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import bus from '../../store/action_bus.js'
import Vue from 'vue'
import clone from 'clone'
import { partnerStruct } from 'helpers/partners.js'

import ContextTitle from '../../components/context_title.vue'
import PartnerForm from 'components/partners/partner_form.vue'
import PartnerFormValidator from 'mixins/partner_form_validator.js'

export default {
    name: 'PartnerCreate',
    components: {
        ContextTitle,
        PartnerForm,
    },
    mixins: [
        PartnerFormValidator,
    ],
    data() {
        return {
            partner: partnerStruct,
        }
    }, 
    computed: {
        titleBarActions() {
            const cancel = {
                type: 'cancel',
                display: 'Cancel',
                run: this.cancel,
            }

            const save = {
                type: 'proceed',
                display: 'Save',
                run: this.save,
            }
            
            return [cancel, save]
        },
        ...mapGetters('PartnerStore', [
            'partnerList',
        ])
    },
    methods: {
        save() {
            if (!this.validateForm()) return
            const cloned = clone(this.partner)
            this.createPartner(cloned).then(() => {
                this.$router.push({name: 'partners'})
            })
        },
        cancel() {
            this.$router.push({name: 'partners'})
        },
        ...mapActions('PartnerStore', [
            'loadAllPartnerInformation',
            'createPartner',
        ])
    },
}
</script>

<style lang="scss">
</style>
